/* globals ScrollMagic */
$(function() {
    // let vh = window.innerHeight * 0.01;
    // document.documentElement.style.setProperty('--vh', `${vh}px`);

    // window.addEventListener('resize', () => {
    //     let vh = window.innerHeight * 0.01;
    //     document.documentElement.style.setProperty('--vh', `${vh}px`);
    // });

    // console.log(window.innerHeight, $(window).height(), window.screen.height);
    // var isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);

    $('body').addClass('snap-fix');
    setTimeout(() => {
        $('body').removeClass('snap-fix');
    }, 1000);

    function killVideo(slide, videoWrapper) {
        // console.log('buffer', Math.round($('video', videoWrapper)[0].buffered.end(0)) / Math.round($('video', videoWrapper)[0].seekable.end(0)));
        // if ($('video', videoWrapper)[0].readyState != 4 || !videoWrapper.hasClass('done')) {

        if (Math.round($('video', videoWrapper)[0].buffered.end(0)) / Math.round($('video', videoWrapper)[0].seekable.end(0)) < 1) {
            // console.log('readyState', $('video', videoWrapper)[0].readyState);
            // console.log('reset the video');
            // $('video', videoWrapper)[0].src = '';
            // $('video', videoWrapper).attr('data-update_src', true);

            $('video', videoWrapper)[0].pause(0);
            var src = $('video', videoWrapper).attr('data-src');
            var poster = $('video', videoWrapper).attr('poster');
            // console.log(slide.attr('id'), src, poster);
            $('video', videoWrapper)[0].src = "";
            $('video', videoWrapper)[0].load();
            $('video', videoWrapper).remove();
            // console.log(slide.attr('id'), src, poster);

            videoWrapper.html(`<video poster="${poster}" muted playsinline webkit-playsinline preload="metadata"
                                    data-src="${src}">
                                    <source src="${src}" type="video/mp4">
                                </video>`);

            if ($('.slides').attr('data-sound') == 'on') {
                $('video', videoWrapper)[0].muted = false;
            }

            $('video', videoWrapper).on('timeupdate', function () {
                // console.log(this.duration, this.currentTime);
                var percent = this.currentTime / this.duration * 100;
                $('.video-controls .elapsed', slide).css('width', percent + '%');
                videoWrapper.attr('data-currentTime', this.currentTime);
                if (this.currentTime >= this.duration) {
                    $(videoWrapper).addClass('done');
                    $('.video-controls', slide).attr('data-mode', 'done');
                }
            });
        }
    }


    var slideController = new ScrollMagic.Controller();
    $('.slide').each(function() {
        var slide = $(this);

        var videoWrapper = $('.video-wrapper', slide);

        if(videoWrapper.length) {
            $('video', videoWrapper).on('timeupdate', function() {
                // console.log(this.duration, this.currentTime);
                var percent = this.currentTime / this.duration * 100;
                $('.video-controls .elapsed', slide).css('width', percent+'%');
                videoWrapper.attr('data-currentTime', this.currentTime);
                if(this.currentTime >= this.duration) {
                    $(videoWrapper).addClass('done');
                    $('.video-controls', slide).attr('data-mode', 'done');
                }
            });
        }

        // enter
        var enterScene = new ScrollMagic.Scene({
            triggerHook: 'onEnter',
            triggerElement: $(slide)[0],
            offset: ($(window).height()/100)*20,
            // duration: ($(window).height() / 100) * 90
            duration: $(window).height()
        });

        enterScene.on('enter', function(event) {
            if (event.scrollDirection == 'FORWARD') {
                // console.log('down-start ', slide.attr('id'));
                if (videoWrapper.length) {
                    if($('.global-sound').length) {
                        setTimeout(() => {
                            $('.global-sound').remove();
                        }, 5000);
                    }

                    if (videoWrapper.attr('data-currentTime')) {
                        $('video', videoWrapper)[0].currentTime = videoWrapper.attr('data-currentTime');
                    }

                    var promise = $('video', videoWrapper)[0].play();
                    if (promise !== undefined) {
                        promise.then(function () {
                            $('video', videoWrapper)[0].play();
                            $('.video-controls', slide).attr('data-mode', 'playing');

                            // show the pause
                        }).catch(function (error) {
                            console.log(error, slide.attr('id'), 'enter down video playing failed');
                        });
                    }
                }
            }
        });
        
        enterScene.on('leave', function (event) {
            if (event.scrollDirection == 'FORWARD') {
                // console.log('down-stop ', slide.attr('id'));
                if (videoWrapper.length) {
                    var promise = $('video', videoWrapper)[0].play();
                    if (promise !== undefined) {
                        promise.then(function () {
                            // if ($('body').hasClass('kill')) {
                            //     killVideo(slide, videoWrapper);
                            // }

                            $('video', videoWrapper)[0].pause();
                            $('.video-controls', slide).attr('data-mode', 'paused');
                            
                            // show the play
                        }).catch(function (error) {
                            console.log(error, 'video pausing failed');
                        });
                    }
                }
            }
        });

        if ($('body').hasClass('debug-down')) {
            enterScene.addIndicators({ name: 'enter '+slide.attr('id')});
        }

        enterScene.addTo(slideController);

        // enter kill
        var enterKillScene = new ScrollMagic.Scene({
            triggerHook: 'onLeave',
            triggerElement: $(slide)[0],
            duration: $(window).height(),
            offset: $(window).height()
        });
        enterKillScene.on('enter', function (event) {
            if (event.scrollDirection == 'FORWARD') {
                // console.log('kill down', slide.attr('id'));
                if (videoWrapper.length && $('body').hasClass('kill')) {
                    killVideo(slide, videoWrapper);
                }
            }
        });

        // enterKillScene.addIndicators({ name: 'kill enter ' + slide.attr('id') });

        enterKillScene.addTo(slideController);

        // leave
        // var leaveOffset = 0;
        // if ('ontouchstart' in document.documentElement) {
        //     leaveOffset = window.screen.height - window.innerHeight;
        // }
        // console.log(leaveOffset);

        var enterLeave = new ScrollMagic.Scene({
            triggerHook: 'onLeave',
            triggerElement: $(slide)[0],
            offset: - ($(window).height() / 100) * 10,
            duration: ($(window).height() / 100) * 80
        });

        enterLeave.on('enter', function (event) {
            if (event.scrollDirection == 'REVERSE') {
                // console.log('up-start ', slide.attr('id'));
                if (videoWrapper.length) {
                    if (videoWrapper.attr('data-currentTime')) {
                        $('video', videoWrapper)[0].currentTime = videoWrapper.attr('data-currentTime');
                    }

                    var promise = $('video', videoWrapper)[0].play();
                    if (promise !== undefined) {
                        promise.then(function () {
                            $('video', videoWrapper)[0].play();
                            $('.video-controls', slide).attr('data-mode', 'playing');

                            // show the pause
                        }).catch(function (error) {
                            console.log(error, slide.attr('id'), 'enter up video playing failed');
                        });
                    }
                }
            }
        });

        enterLeave.on('leave', function (event) {
            if (event.scrollDirection == 'REVERSE') {
                // console.log('up-stop ', slide.attr('id'));
                if (videoWrapper.length) {
                    var promise = $('video', videoWrapper)[0].play();
                    if (promise !== undefined) {
                        promise.then(function () {
                            // if ($('body').hasClass('kill')) {
                            //     killVideo(slide, videoWrapper);
                            // }

                            $('video', videoWrapper)[0].pause();
                            $('.video-controls', slide).attr('data-mode', 'paused');
                            // show the pause
                        }).catch(function (error) {
                            console.log(error, slide.attr('id'), 'leave up video playing failed');
                        });
                    }
                }
            }
        });

        // enterLeave.addIndicators({ name: 'leave ' + slide.attr('id') });

        enterLeave.addTo(slideController);

        // // enter kill
        // var enterLeaveKillScene = new ScrollMagic.Scene({
        //     triggerHook: 'onEnter',
        //     triggerElement: $(slide)[0],
        //     offset: -$(window).height(),
        //     duration: $(window).height()
        // });
        // enterLeaveKillScene.on('enter', function (event) {
        //     if (event.scrollDirection == 'REVERSE') {
        //         console.log('kill up', slide.attr('id'));
        //         if (videoWrapper.length && $('body').hasClass('kill')) {
        //             killVideo(slide, videoWrapper);
        //         }
        //     }
        // });

        // // enterLeaveKillScene.addIndicators({ name: 'kill enterLeave ' + slide.attr('id') });

        // enterLeaveKillScene.addTo(slideController);
    });
    

    // window.addEventListener('resize', () => {
    //     // slideController.update(true);
    //     console.log(window.innerHeight, $(window).height(), window.screen.height);
    // });


    // play-pause 
    $('body').on('click', '.slide .play-pause', function() {
        var slide = $(this).parents('.slide');

        var video = $('video', slide)[0];
        if (video.paused) {
            video.play();
            $('.video-controls', slide).attr('data-mode', 'playing');
        } else {
            video.pause();
            $('.video-controls', slide).attr('data-mode', 'paused');
        }

        return false;
    });

    // seeker
    var videoProgress = {
        moving:false,
        slide: null,
        track:null,
        trackLeft:null,
        trackWidth:null,
        trackWidthPercent:null,
        elapsed:null,
        grabber:null,
        grabberOffset:0,
        grabberLeft: 0,
        grabberLeftPercent: 0
    };

    $('body').on('mousedown', '.video-controls .grabber', function (e) {
        e.preventDefault();

        videoProgress.slide = $(this).parents('.slide');
        videoProgress.moving = true;
        videoProgress.track = $('.track', videoProgress.slide);
        videoProgress.trackLeft = videoProgress.track.position().left;
        videoProgress.trackWidth = videoProgress.track.width();
        videoProgress.trackWidthPercent = videoProgress.trackWidth / 100;
        videoProgress.elapsed = $('.elapsed', videoProgress.track);
        videoProgress.grabber = $('.grabber', videoProgress.track);

        // update the offset of the num from the target
        videoProgress.grabberOffset = (e.pageX - videoProgress.trackLeft) - videoProgress.grabber.position().left;
    });

    $('.video-controls .grabber').on('touchstart', function (e) {
        e.preventDefault();

        videoProgress.slide = $(this).parents('.slide');
        videoProgress.moving = true;
        videoProgress.track = $('.track', videoProgress.slide);
        videoProgress.trackLeft = videoProgress.track.position().left;
        videoProgress.trackWidth = videoProgress.track.width();
        videoProgress.trackWidthPercent = videoProgress.trackWidth / 100;
        videoProgress.elapsed = $('.elapsed', videoProgress.track);
        videoProgress.grabber = $('.grabber', videoProgress.track);


        // update the offset of the num from the target
        videoProgress.grabberOffset = (e.originalEvent.touches[0].clientX - videoProgress.trackLeft) - videoProgress.grabber.position().left;
    });

    $('body').on('mousemove', function (e) {

        if (videoProgress.moving) {
            e.preventDefault();

            // update the left with the offset from the grabber
            var left = (e.pageX - videoProgress.trackLeft) - videoProgress.grabberOffset;

            // set the left
            videoProgress.grabberLeft = left;

            // if its less then a 0
            videoProgress.grabberLeft = (videoProgress.grabberLeft < 0 ? 0 : videoProgress.grabberLeft);

            // if its more then the max
            videoProgress.grabberLeft = (videoProgress.grabberLeft > videoProgress.trackWidth ? videoProgress.trackWidth : videoProgress.grabberLeft);

            // update the percent
            videoProgress.grabberLeftPercent = (videoProgress.grabberLeft / videoProgress.trackWidth) * 100;

            // update the elapsed
            videoProgress.elapsed.css({
                width: videoProgress.grabberLeftPercent + '%'
            });

            var video = $('video', videoProgress.slide)[0];
            var duration = video.duration;
            var seekTo = duration * (videoProgress.grabberLeftPercent / 100);
            video.currentTime = seekTo;
        }
    });

    $('.video-controls .grabber').on('touchmove', function (e) {
        e.preventDefault();

        if (videoProgress.moving) {
            e.preventDefault();

            // update the left with the offset from the grabber
            var left = (e.originalEvent.touches[0].clientX - videoProgress.trackLeft) - videoProgress.grabberOffset;

            // set the left
            videoProgress.grabberLeft = left;

            // if its less then a 0
            videoProgress.grabberLeft = (videoProgress.grabberLeft < 0 ? 0 : videoProgress.grabberLeft);

            // if its more then the max
            videoProgress.grabberLeft = (videoProgress.grabberLeft > videoProgress.trackWidth ? videoProgress.trackWidth : videoProgress.grabberLeft);

            // update the percent
            videoProgress.grabberLeftPercent = (videoProgress.grabberLeft / videoProgress.trackWidth) * 100;

            // update the elapsed
            videoProgress.elapsed.css({
                width: videoProgress.grabberLeftPercent + '%'
            });

            var video = $('video', videoProgress.slide)[0];
            var duration = video.duration;
            var seekTo = duration * (videoProgress.grabberLeftPercent / 100);
            video.currentTime = seekTo;
        }
    });

    $('body').on('mouseup', function () {
        if (videoProgress.moving) {
            videoProgress.moving = false;
            videoProgress.slide = null;
            videoProgress.track = null;
            videoProgress.trackLeft = null;
            videoProgress.trackWidth = null;
            videoProgress.trackWidthPercent = null;
            videoProgress.elapsed = null;
            videoProgress.grabber = null;
            videoProgress.grabberOffset = 0;
            videoProgress.grabberLeft = 0;
            videoProgress.grabberLeftPercent = 0;
        }
    });

    $('.video-controls .grabber').on('touchend', function (e) {
        e.preventDefault();

        if (videoProgress.moving) {
            videoProgress.moving = false;
            videoProgress.slide = null;
            videoProgress.track = null;
            videoProgress.trackLeft = null;
            videoProgress.trackWidth = null;
            videoProgress.trackWidthPercent = null;
            videoProgress.elapsed = null;
            videoProgress.grabber = null;
            videoProgress.grabberOffset = 0;
            videoProgress.grabberLeft = 0;
            videoProgress.grabberLeftPercent = 0;
        }
    });

    // sound mute
    $('body').on('click', '.sound-mute', function() {
        var sound = $('.slides').attr('data-sound');
        
        if(sound == 'off') {
            $('.slides').attr('data-sound', 'on');
            $('.video-wrapper video').each(function() {
                var video = $(this)[0];
                video.muted = false;
            });
            $('.global-sound').remove();
            return false;
        }

        if(sound == 'on') {
            $('.slides').attr('data-sound', 'off');
            $('.video-wrapper video').each(function () {
                var video = $(this)[0];
                video.muted = true;
            });
            return false;
        }
        
        return false;
    });

    $('body').on('click', '.global-sound', function() {
        $('.slides').attr('data-sound', 'on');
        $('.video-wrapper video').each(function () {
            var video = $(this)[0];
            video.muted = false;
        });
        
        $('.global-sound').remove();
        return false;
    });
});